'use strict'

/**
 * White list for all attribute that are mergeable for the merging process of defaultConfiguration with userConfiguration
 */
export const whitelist = {
  /**
   * Alias credit card
   */
  aliasCreditCard: {
    fields: {
      code: ['label', 'placeholder', 'selector']
    }
  },
  /**
   * Credit card
   */
  creditCard: {
    fields: {
      number: ['label', 'placeholder', 'selector'],
      code: ['label', 'placeholder', 'selector', 'required'],
      expiry: ['label', 'placeholder', 'selector'],
      holder: ['label', 'placeholder', 'selector', 'required']
    }
  },
  /**
   * Bank account
   */
  bankAccount: {
    fields: {
      iban: ['label', 'placeholder', 'selector'],
      bic: ['label', 'placeholder', 'selector'],
      holder: ['label', 'placeholder', 'selector']
    }
  },
  /**
   * White list for all style declarations that are accepted
   */
  styles: [
    '-moz-appearance',
    '-moz-osx-font-smoothing',
    '-moz-tap-highlight-color',
    '-moz-transition',
    '-webkit-appearance',
    '-webkit-font-smoothing',
    '-webkit-tap-highlight-color',
    '-webkit-transition',
    'appearance',
    'color',
    'background',
    'background-color',
    'background-image',
    'background-origin',
    'background-position',
    'background-repeat',
    'background-size',
    'background-attachment',
    'direction',
    'font',
    'font-family',
    'font-size',
    'font-size-adjust',
    'font-stretch',
    'font-style',
    'font-variant',
    'font-variant-alternates',
    'font-variant-caps',
    'font-variant-east-asian',
    'font-variant-ligatures',
    'font-variant-numeric',
    'font-weight',
    'letter-spacing',
    'line-height',
    'opacity',
    'outline',
    'text-shadow',
    'transition'
  ]
}

export type Configuration = Partial<typeof defaultConfiguration> & { creditCards: object }

/**
 * The default configuration.
 */
export const defaultConfiguration = {
  aliasCreditCard: {
    fields: {
      code: {
        name: 'cvc',
        label: 'CVC',
        minLength: 3,
        maxLength: 4,
        type: 'tel', //for mobile devices
        placeholder: '',
        pattern: /^\d*$/,
        required: true
      }
    }
  },
  creditCard: {
    fields: {
      number: {
        name: 'credit-card-number',
        label: 'Card Number',
        maxLength: 22,
        type: 'tel', //for mobile devices
        placeholder: '',
        pattern: /^\d*$/,
        required: true
      },
      code: {
        name: 'cvc',
        label: 'CVC',
        minLength: 3,
        maxLength: 4,
        type: 'tel', //for mobile devices
        placeholder: '',
        pattern: /^\d*$/,
        required: true
      },
      expiry: {
        name: 'expiry',
        label: 'Expiration Date',
        minLength: 5,
        maxLength: 7,
        type: 'tel', //for mobile devices
        placeholder: '',
        pattern: /^(0\d|(1[012]))\/((20\d{2})|(\d{2}))$/,
        required: true
      },
      holder: {
        name: 'cardholder',
        label: 'Cardholder',
        minLength: 3,
        maxLength: 100,
        placeholder: '',
        pattern: /.*/,
        required: false
      }
    }
  },
  bankAccount: {
    fields: {
      iban: {
        name: 'iban',
        label: 'IBAN',
        minLength: 18,
        maxLength: 34,
        type: 'text',
        placeholder: '',
        pattern: /^([A-Z]{1,2}[0-9]{0,2})$|^([A-Z]{2}\d{2}[A-Z0-9]+)$/,
        required: true
      },
      bic: {
        name: 'bic',
        label: 'BIC',
        minLength: 8,
        maxLength: 11,
        type: 'text',
        placeholder: '',
        pattern: /^[A-Z]{0,4}[A-Z]{0,2}([A-Z]|\d){0,2}([A-Z]|\d){0,3}$/,
        required: true
      },
      holder: {
        name: 'accountHolder',
        label: 'Account Holder',
        minLength: 3,
        maxLength: 100,
        placeholder: '',
        pattern: /.*/,
        required: true
      }
    }
  }
}
export default {
  defaultConfiguration: defaultConfiguration,
  whitelist: whitelist
}
