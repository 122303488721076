'use strict'

export const style = {
  valid: 'ifs-valid',
  invalid: 'ifs-invalid',
  potentialValid: 'ifs-potential-valid',
  notAccepted: 'ifs-not-accepted',
  focused: 'ifs-focused',
  required: 'ifs-required'
}

export default { style }
