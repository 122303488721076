'use strict'

/**
 * Events that are used internal.
 *
 * @enum
 * @private
 */
export const EVENTS = {
  CARD_BRAND_CHANGE: 'CARD_BRAND_CHANGE',
  CLEAR_FIELDS: 'CLEAR_FIELDS',
  FOCUS_CHANGED: 'FOCUS_CHANGED',
  FORM_INPUT: 'FORM_INPUT',
  FRAME_READY: 'FRAME_READY',
  IBAN_TYPE_CHANGE: 'IBAN_TYPE_CHANGE',
  TOKENIZATION_REQUEST: 'TOKENIZATION_REQUEST',
  FORWARD_TO_ACS: 'FORWARD_TO_ACS',
  RETURN_FROM_ACS: 'RETURN_FROM_ACS',
  FORWARD_TO_METHOD: 'FORWARD_TO_METHOD',
  RETURN_FROM_METHOD: 'RETURN_FROM_METHOD',
  SPECIAL_KEY_PRESSED: 'SPECIAL_KEY_PRESSED',
  VALIDATION_CHANGE: 'VALIDATION_CHANGE',
  FORM_VALIDATION_CHANGE: 'FORM_VALIDATION_CHANGE',
  ACTIVE_STATE_CHANGE: 'ACTIVE_STATE_CHANGE',
  EXCEPTION: 'EXCEPTION',
  REPORT_ERROR: 'REPORT_ERROR',
  START_TIMEOUT: 'START_TIMEOUT',
  STOP_TIMEOUT: 'STOP_TIMEOUT'
}

/**
 * User events that are handled within the shop.
 *
 * @enum
 * @public
 */
export const USER_EVENTS = {
  /**
   * Event that's fired if all iframes are created and the instance is ready
   * @public
   */
  ready: 'ready',
  /**
   * Event that's fired if the type of card has changed
   * @public
   */
  cardBrandChange: 'cardBrandChange',
  /**
   * Event that's fired if the type of iban has changed
   * @public
   */
  ibanTypeChange: 'ibanTypeChange',
  /**
   * Event that's fired if current validation state has changed
   * @public
   */
  validationChange: 'validationChange',
  /**
   * Event that's fired if current form validation state has changed
   * @public
   */
  formValidationChange: 'formValidationChange',
  /**
   * Event that's fired if current focus has changed
   * @public
   */
  focusChange: 'focusChange',
  /**
   * Event that's fired if activation station of an instance is changed.
   * @public
   */
  activeStateChange: 'activeStateChange',
  /**
   * Event that's fired if submit request is triggered by the user.
   * @public
   */
  submitRequest: 'submitRequest'
}

if (process.env.DEV) {
  USER_EVENTS['formInput'] = 'formInput'
}

export default {
  events: EVENTS,
  userevents: USER_EVENTS
}
