/**
 * Returns the class names of the given element.
 *
 * @param element the HTML element
 * @returns {Array} array with class names
 * @private
 */
function _classesOf(element) {
  return element.className.trim().split(/\s+/)
}

/**
 * Adds all given parameters (after the first one) as classes to an element.
 *
 * @param element the HTML element
 * @private
 */
function add(element, ...toAdd) {
  var newClassName = _classesOf(element)
    .filter((classname) => {
      return toAdd.indexOf(classname) === -1
    })
    .concat(toAdd)
    .join(' ')

  element.className = newClassName
}

/**
 * Removes all given parameters (after the first one) from an element.
 *
 * @param element the HTML element
 * @private
 */
function remove(element, ...toRemove) {
  var className = _classesOf(element)
    .filter((classname) => {
      return toRemove.indexOf(classname) === -1
    })
    .join(' ')

  element.className = className
}

/**
 * Toggle a single classname.
 *
 * @param element the HTML element
 * @param classname the class name that should be toggled
 * @param adding class name should be added (true) or removed (false)
 * @private
 *
 */
function toggle(element, classname, adding) {
  if (adding) add(element, classname)
  else remove(element, classname)
}

export default {
  add,
  remove,
  toggle
}
