import wrapPromise = require('@braintree/wrap-promise')
import Integration, { Integration as IntegrationType } from './integration'
import { USER_EVENTS } from '../library/events'
import { Promise } from '../library/promise'

//other instances
global.instances = []

/**
 * Initializing the integration with the given options. Before the initializing the options are checked and
 * if options are invalid an exception is thrown.
 *
 * @name integration.initialize
 * @function
 * @param {Options} options
 * @return {Promise<Integration>}
 */
function initialize(options: Options): Promise<Integration> {
  let ifsInstance: IntegrationType

  console.log('in initialize')

  return new Promise((resolve) => {
    ifsInstance = new Integration(options)
    global.instances.push(ifsInstance)

    ifsInstance.on(USER_EVENTS.ready, () => {
      console.log('received ready')
      resolve(ifsInstance)
    })

    console.log('Promise created')
  })
}

/**
 * @namespace integration
 */
module.exports = { initialize: wrapPromise(initialize) }