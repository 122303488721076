'use strict'

/**
 * @public
 * @constructor
 */
export class LibraryError {
  category
  type
  detail

  constructor(options: { category; type; detail }) {
    if (!LibraryError.categories.hasOwnProperty(options.category)) {
      throw new Error(options.category + ' is not a valid category.')
    }

    if (!LibraryError.types.hasOwnProperty(options.type)) {
      throw new Error(options.type + ' is not a valid type.')
    }

    if (!options.detail) {
      throw new Error('Error detail required.')
    }

    /**
     * @public
     * @type {LibraryError.categories}
     */
    this.category = options.category

    /**
     * @public
     * @type {LibraryError.types}
     */
    this.type = options.type

    /**
     * @public
     * @type {string}
     */
    this.detail = options.detail
  }

  /**
   * @param category
   * @param type
   * @param detail
   * @public
   */
  static create(category, type, detail) {
    return new this({ category: category, type: type, detail: detail })
  }
  /**
   *
   * @returns {string}
   */
  toString() {
    return '[' + this.category + '] ' + this.type + ': ' + this.detail
  }

  /**
   * @memberOf LibraryError
   * @enum
   * @public
   */
  static categories = {
    /**
     * Errors resulting from communication problems
     */
    COMMUNICATION: 'COMMUNICATION',
    /**
     * Errors resulting from transaction processing problems
     */
    PROCESSING: 'PROCESSING',
    /**
     * Errors resulting from user input or action
     */
    USER: 'USER',
    /**
     * Errors resulting from API integration
     */
    INTEGRATION: 'INTEGRATION',
    /**
     * Errors resulting from other causes
     */
    OTHER: 'OTHER',
    /**
     * Errors resulting from failures in library code
     */
    VENDOR: 'VENDOR'
  }

  /**
   * @memberOf LibraryError
   * @enum
   * @public
   */
  static types = {
    /**
     * Implementation error: argument(s) missing in function call
     */
    IMPLEMENTATION_MISSING_ARGUMENT: 'IMPLEMENTATION_MISSING_ARGUMENT',
    /**
     * Configuration missing in call to {@link Integration.new}
     */
    CREATE_CONFIGURATION_MISSING: 'CREATE_CONFIGURATION_MISSING',
    /**
     * Selector for field not found
     */
    CREATE_INVALID_SELECTOR: 'CREATE_INVALID_SELECTOR',
    /**
     * Injected style was not accepted
     */
    CREATE_STYLE_NOT_ACCEPTED: 'CREATE_STYLE_NOT_ACCEPTED',
    /**
     * Modifications for an option property is not allowed
     */
    CREATE_MODIFICATIONS_NOT_ALLOWED: 'CREATE_MODIFICATIONS_NOT_ALLOWED',
    /**
     * Some required fields are not set
     */
    CREATE_MISSING_REQUIRED_FIELD: 'CREATE_MISSING_REQUIRED_FIELD',
    /**
     * Some required properties are missing
     */
    CREATE_MISSING_PROPERTY: 'CREATE_MISSING_PROPERTY',
    /**
     * Invalid property
     */
    CREATE_INVALID_PROPERTY: 'CREATE_INVALID_PROPERTY',
    /**
     * Unsupported payment type
     */
    CREATE_UNSUPPORTED_PAYMENT_TYPE: 'CREATE_UNSUPPORTED_PAYMENT_TYPE',
    /**
     * one or more fields contain invalid values
     */
    FORM_FIELDS_INVALID: 'FORM_FIELDS_INVALID',
    /**
     * one or more required fields are empty
     */
    CREATETOKEN_FIELDS_EMPTY: 'CREATETOKEN_FIELDS_EMPTY',
    /**
     * one or more fields contain invalid values
     */
    CREATETOKEN_FIELDS_INVALID: 'CREATETOKEN_FIELDS_INVALID',
    /**
     * kind of transaction not supported
     */
    CREATETOKEN_KIND_OF_TRANSACTION_NOT_SUPPORTED: 'CREATETOKEN_KIND_OF_TRANSACTION_NOT_SUPPORTED',
    /**
     * supplied client session invalid or expired
     */
    CREATETOKEN_CLIENT_SESSION_INVALID: 'CREATETOKEN_CLIENT_SESSION_INVALID',
    /**
     * could not reach API
     */
    CREATETOKEN_CONNECTION_ERROR: 'CREATETOKEN_CONNECTION_ERROR',
    EXCEPTION_UNKNOWN: 'EXCEPTION_UNKNOWN',
    EXCEPTION_ACS_NOT_AUTHENTICATED: 'EXCEPTION_ACS_NOT_AUTHENTICATED',
    EXCEPTION_3DSECURITY_NOT_AVAILABLE: 'EXCEPTION_3DSECURITY_NOT_AVAILABLE'
  }
}

export default LibraryError
