'use strict'

import '../polyfill'

var parser
var whitelistedDomains

if (process.env.DEV) {
  //we need this for our web development environment when we start the server with js server (we have not Servlet to whitelisting)
  whitelistedDomains = ['localhost', process.env.HOSTNAME, 'voeb-zvd.de']
} else {
  whitelistedDomains = ['_WHITELIST_DOMAIN_NAME_']
}

/**
 * Check if given url is an accepted domain.
 *
 * @param url the url to be checked
 */
export function isAcceptedDomain(url) {
  var domain
  url = url.toLowerCase()

  //TODO test for HTTPS?
  //if(!/^https:/.test(url)) return false;

  parser = parser || document.createElement('a')
  parser.href = url

  if (process.env.DEV) {
    domain = removeSubdomain(parser.hostname)
  } else {
    domain = parser.hostname
  }

  var domainIncluded = whitelistedDomains.includes(domain)

  if (!domainIncluded) {
    console.warn('Domain not included: ', domain, ' ', whitelistedDomains)
  }

  return domainIncluded
}

/**
 * Remove the subdomain from given URL.
 *
 * @param url the url to remove subdomain
 * @return {string} main domain
 */
function removeSubdomain(url) {
  return url
    .split('.')
    .slice(-2)
    .join('.')
}

export default {
  isAcceptedDomain: isAcceptedDomain
}
