'use strict'

import '../polyfill'

import { USER_EVENTS } from '../library/events'

export class EventEmitter {
  private _events

  constructor() {
    this._events = {}
  }

  /**
   * Bind an event handler to an event that is emit by this object.
   *
   * @param event event name
   * @param handler function as event handler
   * @public
   */
  on(event: IFrameEvent, handler: IFrameEventCallback) {
    if (Object.keys(USER_EVENTS).includes(event)) {
      console.log('EventEmitter', handler.name, 'on event:', event)

      if (this._events[event]) {
        this._events[event].push(handler)
      } else {
        this._events[event] = [handler]
      }
    } else {
      console.warn(event, "couldn't be added because it's not allowed")
    }
  }

  /**
   * Emit the given event.
   *
   * @param event event name
   * @private
   */
  protected _emit(event: IFrameEvent, ...args) {
    var i
    var callbacks = this._events[event] as any[]

    if (!callbacks) {
      console.warn("no callback for event '" + event + "'")
      return
    }

    callbacks.forEach((cb) => {
      cb(...args)
    })
  }

  // alias
  publish = this._emit
}

export default EventEmitter
