'use strict'

import Framebus = require('framebus')
import { LibraryError } from './libraryerror'
import { isAcceptedDomain } from '../library/domainwhitelist'

const framebus = new Framebus()

/**
 * Creates a new instance of our public subscriber.
 *
 * @constructor
 * @param configuration
 * @private
 */
export class PubSub {
  fsId
  merchantUrl

  constructor(configuration?) {
    var conf = configuration || {}

    this.fsId = conf.fsId
    this.merchantUrl = conf.merchantUrl

    if (!this.fsId) {
      throw new LibraryError({
        category: LibraryError.categories.VENDOR,
        type: LibraryError.types.IMPLEMENTATION_MISSING_ARGUMENT,
        detail: 'fsId must be specified.'
      })
    }

    this._trace('new PubSub created', [location.href])
  }

  /**
   * Publishes an event.
   * @param name event name
   * @protected
   */
  publish(name, ...args) {
    let modifiedArguments = [this._withFsId(name), ...args]

    this._trace('publish', modifiedArguments)
    framebus.emit.apply(framebus, modifiedArguments)
  }

  /**
   * Returns the fs id
   * @returns {*}
   * @protected
   */
  getFsId() {
    return this.fsId
  }
  /**
   * Subscribe an event
   * @param name name of event
   * @param eventHandler function as event handler
   * @protected
   */
  subscribe(name, eventHandler) {
    var modifiedArguments = Array.prototype.slice.call(arguments)
    var self = this
    modifiedArguments[0] = this._withFsId(name)

    if (this.merchantUrl) {
      modifiedArguments[1] = function (...args) {
        if (checkSameOrigin(this.origin, self.merchantUrl)) {
          eventHandler(...args)
        }
      }
    }
    this._trace('subscribe', modifiedArguments)
    framebus.on.apply(framebus, modifiedArguments)
  }

  /**
   * Trace the process
   * @param msg message
   * @param args arguments
   * @private
   */
  private _trace(msg, args) {
    if (process.env.DEV) {
      console.log(msg, args) // eslint-disable-line no-console
    }
  }

  /**
   * Returns the given name with the fs id as prefix separate by a |.
   * @param name
   * @returns {string} name with fs id prefix
   * @private
   */
  private _withFsId(name) {
    return this.fsId + '|' + name
  }
}

let httpRegEx = /:(80|8080|8282)$/
let httpsRegEx = /:443$/

let parser

/**
 * Checks if the postMessageUrl and merchantUrl are from the same origin.
 *
 * @param postMessageUrl URL of the post message
 * @param merchantUrl merchant url
 * @return {*}
 */
function checkSameOrigin(postMessageUrl, merchantUrl) {
  var merchantOrigin, merchantHost
  var parser = parser || document.createElement('a')

  parser.href = merchantUrl

  if (parser.protocol === 'https:') {
    merchantHost = parser.host.replace(httpsRegEx, '')
  } else if (parser.protocol === 'http:') {
    merchantHost = parser.host.replace(httpRegEx, '')
  } else {
    merchantHost = parser.host
  }

  merchantOrigin = parser.protocol + '//' + merchantHost

  if (merchantOrigin === postMessageUrl) {
    return true
  }

  parser.href = postMessageUrl

  return isAcceptedDomain(postMessageUrl)
}

export default PubSub
